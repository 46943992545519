<script lang="ts" setup>
import type { TypeToolClimateRisk } from "~/types/contentful";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import type { TypeChartDataLayer } from "~/types/TypeChartDataLayer";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import type { TypeCrtCompany } from "~/types/csv/TypeCrt";

const props = defineProps<{
  fields: TypeToolClimateRisk<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const { data: dataSourceParsed } = await useLazyFetch<
  TypeDataSourceParsed<TypeCrtCompany> & { data: TypeCrtCompany[] }
>("/api/data-source", {
  query: { id: props.fields.dataSource?.sys.id },
  server: false,
});

const regions = computed(() =>
  [...new Set(dataSourceParsed.value?.data.map((row) => row.Region))].sort(),
);

const proteinTypes = computed(() =>
  [
    ...new Set(
      dataSourceParsed.value?.data.map((row) => row["Main Protein Category"]),
    ),
  ]
    .filter((type) => type !== "Multiple")
    .sort(),
);

const companyNames = computed(() => [
  ...new Set(
    dataSourceParsed.value?.data
      .map((company) => company.Company)
      .filter(Boolean),
  ),
]);

const filterPanelBlocks = computed<string[][]>(() => [
  [...regions.value],
  [...proteinTypes.value],
  [...companyNames.value],
]);

const filterTitles = ["Regions", "Protein Types", "Companies"];
const filterFields = ["Region", "Main Protein Category", "Company"];

const user = useUser();

const openModals = reactive<Record<string, boolean>>({});

const chartDataLayer = reactive<TypeChartDataLayer>({
  name: props.fields.name,
  project: dataSourceParsed.value?.content.fields.project?.fields.name,
} as TypeChartDataLayer);

provide("chartDataLayer", chartDataLayer);
</script>

<template>
  <section
    v-if="dataSourceParsed?.content.fields.project"
    id="crt"
    class="tool--climate-risk"
    :class="{ 'no-user': !user }"
  >
    <NavigationTabs
      @select="
        chartDataLayer.chartSelected = $event;
        pushDataLayerEvent('evChartSelected', chartDataLayer);
      "
    >
      <template #default="slotProps">
        <NavigationTabItem
          title="Range"
          :selected-title="slotProps?.selectedTitle"
        >
          <MembersOnly
            :show-content="true"
            :show-push="true"
          >
            <template #push-message>
              <p>
                Login to use the Interactive Tool. Not a member? Sign up today.
              </p>
            </template>
            <template #push-buttons>
              <NuxtLink
                class="btn"
                to="/login"
              >
                Login
              </NuxtLink>
              <button
                class="btn btn--icon btn--video"
                @click="
                  $event.preventDefault();
                  openModals['videoButton'] = true;
                "
              >
                Watch Walkthrough Video
                <LazyOtherModal
                  v-model="openModals['videoButton']"
                  vimeo-video-id="https://vimeo.com/812173671"
                  class="flex items-center justify-center"
                />
              </button>
            </template>
            <ChartCrtChartWrapper
              :project="dataSourceParsed.content.fields.project"
              :filter-panel-blocks="filterPanelBlocks"
              :filter-titles="filterTitles"
              :filter-fields="filterFields"
              :companies="dataSourceParsed.data"
              :regions="regions"
              :protein-types="proteinTypes"
            />
          </MembersOnly>
        </NavigationTabItem>

        <NavigationTabItem
          title="Breakdown"
          :selected-title="slotProps?.selectedTitle"
        >
          <ChartCrtBreakdown
            :project="dataSourceParsed.content.fields.project"
            :filter-panel-blocks="filterPanelBlocks"
            :filter-titles="filterTitles"
            :filter-fields="filterFields"
            :companies="dataSourceParsed.data"
            :regions="regions"
            :protein-types="proteinTypes"
          />
        </NavigationTabItem>
      </template>
    </NavigationTabs>
  </section>
</template>

<style lang="scss" scoped>
.tool--climate-risk {
  position: relative;

  &.no-user {
    &:deep(.tool-chart) {
      opacity: 0.5;
      pointer-events: none;

      .chart__item-titles,
      .tool-chart__keys {
        display: none !important;
      }

      .the-chart {
        padding-bottom: 0 !important;
      }

      .ci-gradient span,
      .ci-value span {
        background: #a1a0a0 !important;
      }
    }

    &:deep(.tabs__item:last-of-type) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
